<template>
  <div>
    <div class="row">
      <div
        :class="
          operationType === 1 ||
          operationType === 2 ||
          operationType === 5 ||
          operationType === 6
            ? 'col-lg-2'
            : 'col-lg-4'
        "
      >
        <div class="row">
          <!-- LEFT 1 -->
          <div class="col-12">
            <Skeletor height="10" v-if="id === null" class="white-box" />
            <div v-else class="white-box text-center center-box">
              <div>
                <h2 class="bold-24 text-black mb-2" v-if="pretty_id">
                  <EditOnClick
                    v-model="pretty_id"
                    type="text"
                    v-on:update="updatePrettyId"
                  />
                </h2>
                <h2 class="bold-24 text-black mb-2" v-else>
                  {{ $t(`incomingProducts.key5`, { id: id }) }}
                </h2>
                <EditOnClick
                  v-model="title"
                  type="text"
                  v-on:update="updateTitle"
                />
                <h2 class="bold-16 text-black">
                  <div>
                    {{ $t("incomingProducts.created") }}
                    <EditOnClick
                      v-model="createdAt"
                      type="date"
                      :editable="false"
                    />
                  </div>
                  <div v-show="operationType !== 3">
                    {{ $t("incomingProducts.premierDate") }}
                    <EditOnClick
                      v-model="deadlineDate"
                      type="date"
                      :editable="true"
                      v-on:update="updateDeadline"
                    />
                  </div>
                  <div v-if="related_warehouse_operation_id">
                    {{ $t("incomingProducts.relatedOperations") }}
                    <span>{{ related_warehouse_operation_id }}</span>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-2"
        v-if="operationType === 1 || operationType === 2 || operationType === 5"
      >
        <Skeletor height="10" v-if="full_price === null" class="white-box" />
        <div v-else class="white-box text-center center-box">
          <div>
            <h2 class="bold-19 text-gray mb-2">
              {{ $t("incomingProducts.value") }}
            </h2>
            <span class="bold-24 text-black"
              ><EditOnClick v-model="full_price" type="price" :editable="false"
            /></span>
            <div class="bold-14 text-gray-dark">
              {{ $t("incomingProducts.delivery") }}
              <EditOnClick
                v-model="delivery_price"
                type="price"
                v-on:update="updateDeliveryPrice"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2" v-if="operationType === 6">
        <Skeletor height="10" v-if="full_price === null" class="white-box" />
        <div v-else class="white-box text-center">
          <div class="text-area">
            <h2 class="bold-19 text-gray mb-2">
              {{ $t("incomingProducts.totalTime") }}
            </h2>
            <div class="bold-24 text-black">
              {{ Math.floor(total_time / 60) }}h
              {{ Math.round(total_time - Math.floor(total_time / 60) * 60) }}m
            </div>
            <div class="bold-14 text-gray-dark" v-if="total_time_left">
              {{ Math.floor(total_time_left / 60) }}h
              {{
                Math.round(
                  total_time_left - Math.floor(total_time_left / 60) * 60
                )
              }}m left
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <!-- CENTER 1 -->
        <div class="col-12">
          <div class="box-spacer-half">
            <div class="flex-wrap d-flex">
              <div class="d-flex me-auto">
                <Skeletor
                  height="10"
                  v-if="id === null"
                  class="rounded-box m-3"
                />
                <button
                  class="btn btn-transparent"
                  v-show="id !== null && status < -2"
                  :disabled="setActiveBtn"
                  v-on:click="setActive()"
                  data-bs-placement="top"
                  :title="$t(`incomingProducts.setActive`)"
                  ref="btn5"
                  v-if="warehouseIncome"
                >
                  <span
                    class="rounded-box ms-auto me-auto mb-2 spin-dark"
                    :class="{ 'loading-spin': setActiveBtn }"
                  >
                    <BaseIcon name="tick-circle" />
                  </span>
                </button>
                <button
                  class="btn btn-transparent"
                  v-show="
                    (id !== null &&
                      operationType === 2 &&
                      status > -3 &&
                      status < 5) ||
                    (status === -2 &&
                      (operationType === 5 || operationType === 6))
                  "
                  :disabled="setActiveBtn"
                  v-on:click="showStartProductionModal()"
                  data-bs-placement="top"
                  :title="
                    status > -2
                      ? 'Create new material outgoing'
                      : `Start ${
                          operationType === 6 ? 'production' : 'sourcing'
                        }`
                  "
                  ref="btn8"
                  v-if="warehouseIncome"
                >
                  <span
                    class="rounded-box ms-auto me-auto mb-2 spin-dark"
                    :class="{ 'loading-spin': setActiveBtn }"
                  >
                    <BaseIcon
                      :name="status > -2 ? 'outgoing-building' : 'calendar'"
                    />
                  </span>
                </button>
                <button
                  class="btn btn-transparent"
                  v-show="
                    id !== null &&
                    ((status < 6 &&
                      status > 0 &&
                      (operationType === 2 ||
                        operationType === 3 ||
                        operationType === 5)) ||
                      (status > 1 &&
                        (operationType === 6 || operationType === 1)))
                  "
                  :disabled="setActiveBtn"
                  v-on:click="setFinished()"
                  data-bs-placement="top"
                  :title="$t(`incomingProducts.setFinished`)"
                  ref="btn6"
                  v-if="warehouseIncome || operationType === 3"
                >
                  <span
                    class="rounded-box ms-auto me-auto mb-2 spin-dark"
                    :class="{ 'loading-spin': setActiveBtn }"
                  >
                    <BaseIcon name="flag" />
                  </span>
                </button>
                <button
                  class="btn btn-transparent"
                  v-show="id !== null && invoice === null"
                  :disabled="loadInvoiceBtn"
                  v-on:click="startGenerateInvoice()"
                  data-bs-placement="top"
                  :title="$t(`incomingProducts.createInvoice`)"
                  ref="btn1"
                  v-if="warehouseOutcome"
                >
                  <span
                    class="rounded-box ms-auto me-auto mb-2 spin-dark"
                    :class="{ 'loading-spin': loadInvoiceBtn }"
                  >
                    <BaseIcon name="invoice-add" />
                  </span>
                </button>
                <button
                  class="btn btn-transparent"
                  v-show="invoice !== null"
                  v-on:click="downloadInvoice()"
                  data-bs-placement="top"
                  :title="$t(`incomingProducts.viewInvoice`)"
                  ref="btn2"
                  v-if="warehouseOutcome"
                >
                  <span class="rounded-box ms-auto me-auto mb-2">
                    <BaseIcon name="invoice-download" />
                  </span>
                </button>
              </div>
              <div class="d-flex flex-column me-auto">
                <span
                  class="badge"
                  :class="operationTypes[operationType]?.color"
                >
                  {{ operationTypes[operationType]?.name }}
                </span>
              </div>
              <div class="d-flex">
                <Skeletor
                  height="10"
                  v-if="id === null"
                  class="rounded-box m-3"
                />
                <div
                  class="d-flex no-wrap dropdown"
                  v-if="warehouseOutcome && operationType !== 2"
                >
                  <button
                    class="btn btn-transparent dropdown-btn"
                    v-show="id !== null && publicTrackingCode === null"
                    :disabled="loadShipBtn"
                    data-bs-toggle="dropdown"
                    v-on:click="loadDeliveryMethods"
                    v-on:mouseover="loadDeliveryMethods"
                  >
                    <span
                      data-bs-placement="top"
                      :title="$t(`incomingProducts.createDeliveryLabel`)"
                      ref="btn3"
                      class="rounded-box ms-auto me-auto mb-2 spin-dark"
                      :class="{ 'loading-spin': loadShipBtn }"
                    >
                      <BaseIcon name="tir" />
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="Label options"
                    data-popper-placement="bottom-start"
                  >
                    <div class="row">
                      <div class="col-12">
                        <button
                          class="dropdown-item regular-12"
                          v-on:click="generateShipping()"
                        >
                          <BaseIcon name="box" />
                          {{ $t("incomingProducts.createDefault") }}
                        </button>
                        <button
                          v-for="method in deliveryMethods.filter(
                            (e) => e.type === 1 || e.type === 5
                          )"
                          :key="method.id"
                          class="dropdown-item regular-12"
                          v-on:click="generateShipping(true, method.id)"
                        >
                          <BaseIcon name="box" />
                          {{ method.name }}
                        </button>
                        <button
                          class="dropdown-item regular-12"
                          v-on:click="generateShipping(false)"
                        >
                          <BaseIcon name="box" />
                          {{ $t("incomingProducts.addManualTrackingCode") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  v-else
                  class="btn btn-transparent dropdown-btn"
                  v-show="id !== null && publicTrackingCode === null"
                  :disabled="loadShipBtn"
                  data-bs-toggle="dropdown"
                  v-on:click="generateShipping(false)"
                >
                  <span
                    data-bs-placement="top"
                    :title="$t(`incomingProducts.addTrackingCode`)"
                    ref="btn3"
                    class="rounded-box ms-auto me-auto mb-2 spin-dark"
                    :class="{ 'loading-spin': loadShipBtn }"
                  >
                    <BaseIcon name="box" />
                  </span>
                </button>
                <button
                  class="btn btn-transparent"
                  v-show="publicTrackingCode !== null"
                  v-on:click="viewTrackingCode()"
                  :disabled="loadShipBtn"
                  data-bs-placement="top"
                  :title="$t(`incomingProducts.viewDeliveryLabel`)"
                  ref="btn4"
                >
                  <span
                    class="rounded-box ms-auto me-auto mb-2 spin-dark"
                    :class="{ 'loading-spin': loadShipBtn }"
                  >
                    <BaseIcon name="delivery-info" />
                  </span>
                </button>
                <div class="d-flex no-wrap dropdown">
                  <button
                    id="printBtn"
                    class="btn btn-transparent dropdown-btn"
                    v-show="
                      id !== null &&
                      (operationType === 1 ||
                        operationType === 6 ||
                        operationType === 5 ||
                        operationType === 2)
                    "
                    :disabled="loadPrintBtn"
                    data-bs-toggle="dropdown"
                    aria-expanded="true"
                  >
                    <span
                      class="rounded-box ms-auto me-auto mb-2 spin-dark"
                      :class="{ 'loading-spin': loadPrintBtn }"
                      ref="btn7"
                      data-bs-placement="top"
                      :title="$t(`incomingProducts.print`)"
                    >
                      <BaseIcon name="printer" />
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="Printing options"
                    data-popper-placement="bottom-start"
                  >
                    <div class="row">
                      <div class="col-12">
                        <button
                          class="dropdown-item regular-12"
                          v-if="operationType === 6"
                          v-on:click="downloadProductionPdf()"
                        >
                          <BaseIcon name="task-list" />
                          {{ $t("incomingProducts.productionPages") }}
                        </button>
                        <button
                          class="dropdown-item regular-12"
                          v-show="operationType !== 5"
                          v-on:click="downloadProductsLabels()"
                        >
                          <BaseIcon name="barcode" />
                          {{ $t("incomingProducts.productLabels") }}
                        </button>
                        <button
                          class="dropdown-item regular-12"
                          v-show="operationType !== 5"
                          v-on:click="
                            downloadProductsLabels(full_price.currency)
                          "
                        >
                          <BaseIcon name="barcode" />
                          {{ $t("incomingProducts.productLabelsWithPrices") }}
                        </button>
                        <button
                          class="dropdown-item regular-12"
                          v-if="operationType !== 5"
                          v-on:click="downloadBoxesLabels()"
                        >
                          <BaseIcon name="box" />
                          {{ $t("incomingProducts.boxLabels") }}
                        </button>
                        <button
                          class="dropdown-item regular-12"
                          v-else
                          v-on:click="showBoxesLabelModal()"
                        >
                          <BaseIcon name="box" />
                          {{ $t("incomingProducts.boxLabels") }}
                        </button>
                        <button
                          class="dropdown-item regular-12"
                          v-on:click="downloadXlsx()"
                        >
                          <BaseIcon name="active" />
                          {{ $t("incomingProducts.xlsx") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="toast fade popup position-fixed text-start"
          aria-live="assertive"
          aria-atomic="true"
          ref="askDeliveryCode"
          v-if="askDeliveryCode"
        >
          <div class="toast-header">
            <strong class="me-auto">
              {{ $t("incomingProducts.setTrackingCode") }}
            </strong>
          </div>
          <div class="toast-body">
            <div class="custom-input whitebg-input">
              <input
                type="text"
                class="form-control me-2 mb-2"
                v-model="publicTrackingCode"
                :placeholder="$t(`incomingProducts.insertTrackingCode`)"
                v-if="!automaticDelivery"
              />
              <v-select
                v-if="!automaticDelivery"
                v-model:options="couriers"
                v-on:search="searchCourier"
                :filterable="false"
                v-model="courier"
                label="name"
                :placeholder="$t(`incomingProducts.searchForACourier`)"
              >
                <template v-slot:option="option">
                  <img :src="option.logo" alt="" class="w-3rem me-3" />
                  <span>{{ option.name }}</span>
                </template>
                <template v-slot:selected-option="option">
                  <img :src="option.logo" alt="" class="w-3rem me-3" />
                  <span>{{ option.name }}</span>
                </template>
              </v-select>
              <label
                class="form-label bold-12 text-gray"
                v-if="automaticDelivery"
                >{{ $t("incomingProducts.packagesNumber") }}</label
              >
              <input
                v-if="automaticDelivery"
                type="number"
                class="form-control me-2"
                v-model="packages"
                min="1"
                placeholder="1"
              />
              <div class="text-end">
                <button
                  class="btn btn-primary mt-2"
                  v-on:click="createShipping()"
                  :disabled="loadShipBtn"
                >
                  {{ $t("finance.save") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="toast fade popup position-fixed text-start"
          aria-live="assertive"
          aria-atomic="true"
          ref="askInvoicePaymentType"
          v-if="askInvoicePaymentType"
        >
          <div class="toast-header">
            <strong class="me-auto">
              {{ $t("incomingProducts.invoiceSettings") }}
            </strong>
          </div>
          <div class="toast-body">
            <div class="custom-input whitebg-input">
              <div class="overflow-auto max-h-300">
                <b>{{ $t("bankAccount.currency") }}</b>
                <label
                  class="cstm-check dropdown-item"
                  v-for="currency in currencies"
                  :key="currency"
                >
                  <span class="text-black mx-2 regular-14">{{ currency }}</span>
                  <input
                    type="radio"
                    v-model="paymentCurrency"
                    :value="currency"
                  /><span class="checkmark mx-2"></span>
                </label>
                <b>{{ $t("incomingProducts.paymentType") }}</b>
                <label
                  class="cstm-check dropdown-item"
                  v-for="method in paymentMethods"
                  :key="method.id"
                >
                  <span class="text-black mx-2 regular-14">{{
                    method.name
                  }}</span>
                  <input
                    type="radio"
                    v-model="paymentMethod"
                    :value="method.id"
                  /><span class="checkmark mx-2"></span>
                </label>
              </div>
              <button class="btn btn-primary" v-on:click="generateInvoice()">
                {{ $t("incomingProducts.createInvoice") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <Skeletor height="10" v-if="status === null" class="white-box-half" />
          <div
            v-else
            class="white-box-half text-center bg-gradient"
            :class="statuses[status].color"
          >
            <div class="text-area">
              <h2 class="bold-24">{{ statuses[status].name }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="row">
          <!-- RIGHT 1 -->
          <div
            class="col-6 arrow-right"
            :class="{ 'arrow-load': warehouseOutcome === null }"
            v-if="partner === null || operationType === 3"
          >
            <Skeletor
              height="10"
              v-if="warehouseOutcome === null"
              class="white-box"
            />
            <div v-else class="white-box text-center">
              <div class="text-area">
                <h2 class="bold-24 text-black">{{ warehouseOutcome.name }}</h2>
              </div>
            </div>
          </div>
          <div
            v-else
            class="col-6 arrow-right"
            :class="{ 'arrow-load': partner === null }"
          >
            <Skeletor height="10" v-if="partner === null" class="white-box" />
            <div v-else class="white-box text-center">
              <div class="text-area">
                <h2 class="bold-24 text-black" v-if="status < -1">
                  <CrmItemFinder
                    type="partners"
                    v-model="partner"
                    className="text-center"
                    v-on:update="updatePartner"
                  />
                </h2>
                <h2 class="bold-24 text-black" v-else>
                  {{ partner.name }}
                </h2>
                <div
                  class="regular-12 text-center"
                  v-if="warehouseOutcome !== null"
                >
                  {{
                    $t(`incomingProducts.materialsFrom`, {
                      name: warehouseOutcome?.name,
                    })
                  }}
                </div>
              </div>
            </div>
          </div>
          <!-- RIGHT 2 -->
          <div class="col-6 arrow-left">
            <Skeletor
              height="10"
              v-if="
                warehouseIncome === null &&
                (partner === null || operationType !== 3)
              "
              class="white-box"
            />
            <div v-else class="white-box text-center">
              <div class="text-area">
                <h2 class="bold-24 p-1">
                  {{
                    partner !== null && operationType === 3
                      ? partner.name
                      : warehouseIncome.name
                  }}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BIG LIST -->
    <div class="row mb-5">
      <div class="col-12">
        <div class="nature-white-box">
          <TopBar
            v-model:selectAll="selectAll"
            v-model:line-options="lineOptions"
            v-model:rows="rows"
            v-model:items="items"
            :show-select="this.editable"
            v-model:selectedLineOption="selectedLineOption"
            :path="'warehouses::' + this.type + '::*'"
            :show-add="this.editable"
            :show-delete="this.editable"
            :show-languages="false"
            :show-currencies="false"
            v-model:selectedItems="selectedItems"
            v-on:delete="deleteItem"
            v-on:addNewRow="addNew = true"
            :methods="methods"
            :btns="pages"
            :export-url="`/${
              viewName === 'production'
                ? 'production'
                : 'warehouses/' + this.type
            }/${this.id}/export/{type}?page=1`"
          />
          <DynamicTable
            v-model:rows="rows"
            :items="filteredItems"
            v-model:select-all="selectAll"
            v-on:order="orderBy"
            :order="order"
            :show-pin="false"
            :select-sub="false"
            check-missing-row="needMore"
            :check-delete-row="['no_need_to_send', 'quantity']"
            :check-delete-row-invert="[true, false]"
            type="products"
            v-on:selected="(e) => (selectedItems = e)"
            v-on:update:val="valUpdated"
            :key="tableKey"
            :fix-height="true"
          />
        </div>
      </div>
    </div>
    <ScanModal
      ref="packagingModal"
      :title="packagingInfo.status"
      :text="packagingInfo.text"
      :icon="packagingInfo.icon"
    />
    <InvoiceModal ref="invoiceModal" v-model="invoice" />
    <NewOutgoing v-model:show="addNew" :id="id" v-on:newItem="newItem" />
    <Modal
      ref="startProductionModal"
      :title="
        status > -2
          ? 'Create new material outgoing'
          : operationType === 5
          ? 'Material income deadlines'
          : `Do you want to send items for ${
              operationType === 6 ? 'production' : 'outgoing'
            }?`
      "
      v-on:success="startProduction"
    >
      <ul>
        <li v-if="operationType === 5">
          {{ $t("incomingProducts.setDeadlinesForThisIncoming") }}
        </li>
        <li v-else>
          {{
            $t(`incomingProducts.itemsInThisSourcingWillBeAddedTo`, {
              expr: operationType === 6 ? "production" : "a new outgoing",
            })
          }}
        </li>
        <li>{{ $t("incomingProducts.youCantUndoThisAction") }}</li>
      </ul>
      <p>{{ $t("incomingProducts.areYouSureYouWantToDoThis") }}</p>
      <div v-if="status < -1" class="mt-4">
        <label class="d-block">{{
          $t("incomingProducts.setUpAStartTime")
        }}</label>
        <DatePicker
          v-model="startDate"
          :onlyPicker="false"
          :show-picker="false"
          :onlyInRange="0"
          :show-time="false"
          :only-one="true"
        />
      </div>
      <div v-if="status < -1" class="mt-4">
        <label class="d-block">
          {{
            $t(`incomingProducts.setUpThePlannedEndDate`, {
              expr: operationType === 6 ? "production" : "delivery",
            })
          }}
        </label>
        <DatePicker
          v-model="endDate"
          :onlyPicker="false"
          :show-picker="false"
          :onlyInRange="0"
          :show-time="false"
          :only-one="true"
        />
      </div>
      <div v-if="status < -1 && operationType !== 3" class="mt-4">
        <label class="d-block">{{
          $t("incomingProducts.setUpADeadline")
        }}</label>
        <DatePicker
          v-model="deadlineDate"
          :onlyPicker="false"
          :show-picker="false"
          :onlyInRange="0"
          :show-time="false"
          :only-one="true"
        />
      </div>
      <div>
        <button
          type="button"
          class="btn btn-primary"
          v-on:click="startProduction"
          :class="{ 'loading-spin': setActiveBtn }"
          :disabled="setActiveBtn"
        >
          <span>{{
            status > -2
              ? "Set outgoing active"
              : `Start ${operationType === 6 ? "production" : "sourcing"}`
          }}</span>
        </button>
      </div>
    </Modal>
    <Modal ref="collectModal" :title="$t(`incomingProducts.collectManually`)">
      <div class="row" v-for="item in selectedItems" :key="item.id">
        <div class="col-3">
          {{ item.serial }}
        </div>
        <div
          class="col"
          v-for="key in Object.keys(item).filter(
            (key) => key.indexOf('characteristics_') > -1
          )"
          :key="key"
        >
          {{ item[key] }}
        </div>
        <div class="col-3">
          <div class="fix-end-input">
            <input
              type="number"
              v-model="item.collected_quantity"
              class="form-control"
            />
            <span>/{{ item.quantity }}</span>
          </div>
        </div>
      </div>
      <div>
        <button class="btn btn-primary" v-on:click="packItemsManually()">
          <span>{{ $t("incomingProducts.collect") }}</span>
        </button>
      </div>
    </Modal>
    <Modal ref="boxLabelsModal" :title="$t(`incomingProducts.boxLabels`)">
      <div class="row" v-for="item in selectedItems" :key="item.id">
        <div class="col-3">
          {{ item.serial }}
        </div>
        <div
          class="col"
          v-for="key in Object.keys(item).filter(
            (key) => key.indexOf('characteristics_') > -1
          )"
          :key="key"
        >
          {{ item[key] }}
        </div>
        <div class="col-3">
          <input
            type="number"
            v-model="item.vendor_package"
            class="form-control"
          />
        </div>
      </div>
      <div>
        <div class="row w-100">
          <div class="col-6">
            <label class="cstm-check d-flex" for="ct-1">
              <span class="regular-12 text-black mt-3">
                {{ $t("incomingProducts.printOnlyOneLabel") }}
              </span>
              <input type="checkbox" v-model="onlyOneLabel" id="ct-1" />
              <span class="checkmark mt-3"></span>
            </label>
          </div>
          <div class="col-6 text-end">
            <button
              class="btn btn-primary"
              v-on:click="downloadBoxesLabels()"
              :disabled="loadPrintBtn"
              :class="{ 'loading-spin': loadPrintBtn }"
            >
              <span>{{ $t("incomingProducts.printLabels") }}</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import http from "../../modules/http";
import barcodeRead from "../../modules/barcodeRead";
import lineOptions from "../../modules/lineOptions";
import TopBar from "../../components/lists/TopBar";
import DynamicTable from "../../components/lists/DynamicTable";
import { defineAsyncComponent } from "vue";
import printJS from "print-js";
import store from "@/store";
import ItemOperationTypes from "@/modules/ItemOperationTypes";
import Modal from "@/components/modals/Modal";
import DatePicker from "../../components/inputs/DatePicker";
import CrmItemFinder from "../../components/lists/listItems/CrmItemFinder.vue";
import IncomingItemsStatuses from "../../modules/IncomingItemsStatuses";
import incomingstatuses from "../../modules/Incomingstatuses";
import tenant from "../../modules/tenant";
import NewOutgoing from "../../components/modals/NewOutgoing.vue";

const ScanModal = defineAsyncComponent(() =>
  import("../../components/modals/ScanModal.vue")
);
const InvoiceModal = defineAsyncComponent(() =>
  import("../../components/modals/InvoiceModal.vue")
);
const BaseIcon = defineAsyncComponent(() =>
  import("../../components/icons/BaseIcon.vue")
);
const EditOnClick = defineAsyncComponent(() =>
  import("../../components/inputs/EditOnClick.vue")
);

const bootstrap = require("bootstrap");

export default {
  name: "IncomingProducts",
  components: {
    NewOutgoing,
    CrmItemFinder,
    DatePicker,
    Modal,
    InvoiceModal,
    ScanModal,
    BaseIcon,
    EditOnClick,
    DynamicTable,
    TopBar,
  },
  data() {
    return {
      methods: null,
      editable: true,
      id: null,
      pretty_id: null,
      createdAt: null,
      addNew: false,
      incoming: null,
      itemCount: null,
      itemNum: null,
      full_price: null,
      delivery_price: null,
      paymentCurrency: null,
      related_warehouse_operation_id: null,
      partner: null,
      deadlineDate: null,
      startDate: null,
      endDate: null,
      total_time: null,
      total_time_left: null,
      warehouseIncome: null,
      operationType: 0,
      onlyOneLabel: false,
      operationTypes: ItemOperationTypes,
      incomingItemsStatuses: IncomingItemsStatuses,
      warehouseOutcome: null,
      invoice: null,
      deliveryMethod: null,
      publicTrackingCode: null,
      currencies: [],
      couriers: [],
      courier: null,
      title: " - ",
      packages: 1,
      rows: [],
      selectedLineOption: 1,
      lineOptions: [],
      selectAll: false,
      selectedItems: [],
      items: [],
      status: null,
      statuses: incomingstatuses,
      tableKey: 1,
      packagingInfo: {
        icon: null,
        status: "",
        text: "",
      },
      loadInvoiceBtn: false,
      deliveryMethods: [],
      loadedDeliveryMethods: false,
      loadShipBtn: false,
      loadPrintBtn: false,
      setActiveBtn: false,
      askDeliveryCode: false,
      automaticDelivery: null,
      selectedDeliveryMethod: null,
      askInvoicePaymentType: false,
      paymentMethod: 1,
      paymentMethods: [],
      pages: [],
      order: {
        row: null,
        desc: true,
      },
      lastSearchId: null,
    };
  },
  watch: {
    filteredItems() {
      this.tableKey++;
    },
    viewName() {
      this.loadData();
    },
    status() {
      let methods = [];
      if (
        (this.operationType !== 5 && this.status > 4) ||
        (this.type !== "outgoing" && this.operationType === 1)
      ) {
        this.editable = false;
        for (const row of this.rows) {
          if (row.key !== "deadline_date") {
            row.editable = false;
          }
        }
      }
      if (
        (this.status > 0 && this.status < 6 && this.type === "income") ||
        (this.status < 2 && this.type === "outgoing")
      ) {
        methods.push({
          title:
            this.type === "outgoing"
              ? "Scan products to packaging"
              : "Scan products to collect",
          icon: "scanner",
          always: true,
          method: () => {
            this.packagingInfo.icon = null;
            this.packagingInfo.status = "Scan a barcode";
            this.packagingInfo.text = "Scan a barcode to get started";
            this.$refs.packagingModal.showModal();
          },
        });
      }

      if (
        (this.status > 0 && this.operationType === 5) ||
        this.operationType === 3 ||
        this.operationType === 1
      ) {
        methods.push({
          title: "Collect products manually",
          method: () => {
            this.$refs.collectModal.showModal();
          },
        });
      }
      if (
        this.status > -2 &&
        (this.operationType === 5 ||
          this.operationType === 2 ||
          this.operationType === 6)
      ) {
        methods.push({
          title: "Close item",
          method: () => {
            for (const item of this.selectedItems) {
              this.closeItem(item.id);
            }
          },
        });
      }
      this.methods = methods;
    },
    type() {
      if (this.type !== "outgoing" && this.operationType === 1) {
        this.editable = false;
      }
    },
  },
  computed: {
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    type() {
      return this.$router.currentRoute.value.meta?.type;
    },
    viewName() {
      return this.$router.currentRoute.value.meta?.view;
    },
    q() {
      return store.state.topBar.q;
    },
    filteredItems() {
      if (this.q === null || this.q.length < 1) {
        return this.items;
      }
      let q = this.q.toLowerCase();
      return this.items.filter(
        (e) =>
          e.name.toLowerCase().indexOf(q) > -1 ||
          e.serial.toLowerCase().indexOf(q) > -1 ||
          e.sku.toLowerCase().indexOf(q) > -1 ||
          (e.variants?.findIndex((f) => f.sku.toLowerCase().indexOf(q) > -1) ??
            -1) > -1
      );
    },
  },
  mounted() {
    barcodeRead.load(this.barcodeReaded);

    this.loadData();
    if (this.type !== "outgoing" && this.operationType === 1) {
      this.editable = false;
    }
  },
  unmounted() {
    barcodeRead.unload();
  },
  methods: {
    orderBy(order) {
      this.items = undefined;
      this.order = order;
      this.loadData();
    },
    loadDeliveryMethods() {
      if (this.deliveryMethods.length === 0 && !this.loadedDeliveryMethods) {
        this.loadedDeliveryMethods = true;
        http.fetch("/deliveries/methods").then((data) => {
          this.deliveryMethods = data;
        });
      }
    },
    packItemsManually() {
      this.$refs.collectModal.hideModal();
      for (const item of this.selectedItems) {
        if (item.left_outgoing > 0) {
          this.packaging(item.id, item.collected_quantity);
        }
      }
    },
    downloadProductionPdf() {
      const _this = this;
      this.loadPrintBtn = true;
      let url;
      if (this.selectedItems.length > 0) {
        url = `${tenant.getApiBaseUrl()}/production/${
          this.id
        }/pdf?items[]=${this.selectedItems
          .map((e) => e.id)
          .join("&items[]=")}&_token=${store.state.token}`;
      } else {
        url = `${tenant.getApiBaseUrl()}/production/${this.id}/pdf?_token=${
          store.state.token
        }`;
      }
      printJS({
        printable: url,
        onLoadingEnd: function () {
          _this.loadPrintBtn = false;
        },
      });
    },
    downloadProductsLabels(currency = "") {
      const _this = this;
      this.loadPrintBtn = true;

      let url = "";
      if (this.selectedItems.length > 0) {
        url = `${tenant.getApiBaseUrl()}/production/${
          this.id
        }/barcode/?items[]=${this.selectedItems
          .map((e) => e.id)
          .join("&items[]=")}&_token=${store.state.token}&currency=${currency}`;
      } else {
        url = `${tenant.getApiBaseUrl()}/production/${
          this.id
        }/barcode/?_token=${store.state.token}&currency=${currency}`;
      }
      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(url, "_blank");
        _this.loadPrintBtn = false;
      } else {
        printJS({
          printable: url,
          onLoadingEnd: function () {
            _this.loadPrintBtn = false;
          },
        });
      }
    },
    downloadXlsx() {
      window.open(
        `${tenant.getApiBaseUrl()}/warehouses/outgoing/${this.id}/xlsx?_token=${
          store.state.token
        }`,
        "_blank"
      );
    },
    showBoxesLabelModal() {
      this.$refs.boxLabelsModal.showModal();
    },
    downloadBoxesLabels() {
      const _this = this;
      this.loadPrintBtn = true;
      let url = "";
      if (this.selectedItems.length > 0) {
        url = `${tenant.getApiBaseUrl()}/production/${
          this.id
        }/box-label/?items[]=${this.selectedItems
          .map((e) => e.id)
          .join("&items[]=")}&_token=${store.state.token}`;
      } else {
        url = `${tenant.getApiBaseUrl()}/production/${
          this.id
        }/box-label/?_token=${store.state.token}`;
      }
      if (this.operationType === 5) {
        for (const item of this.selectedItems) {
          url += `&packaging[${item.id}]=${item.vendor_package}`;
        }
        if (this.onlyOneLabel) {
          url += "&only_one=1";
        }
      }
      if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
        window.open(url, "_blank");
        _this.loadPrintBtn = false;
      } else {
        printJS({
          printable: url,
          onLoadingEnd: function () {
            _this.loadPrintBtn = false;
          },
        });
      }
    },
    barcodeReaded(barcode) {
      if (barcode === null) {
        return;
      }

      this.packagingInfo.icon = 3;
      this.packagingInfo.status = "Searching";
      this.packagingInfo.text = "just a few moments..";

      let quantity = 1;
      if (barcode[0] === "p") {
        let barcodeData = barcode.split("/");
        if (barcodeData.length < 2) {
          barcodeData = barcode.split("-");
        }
        quantity = parseInt(barcodeData[0].replace("p", ""));
        barcode = barcodeData[1];
      }

      let element = this.items.find((e) => e.sku === barcode);
      if (element !== undefined) {
        this.packaging(element.id, quantity);
      } else {
        this.packagingInfo.icon = 2;
        this.packagingInfo.status = "Barcode not found";
        this.packagingInfo.text =
          "We cannot find any item with this barcode in this transfer.";
        this.$refs.packagingModal.showModal();
      }
    },
    newItem() {
      this.loadData();
    },
    loadData() {
      this.items = [];
      this.tableKey++;
      http
        .fetch(
          `/${
            this.viewName === "production"
              ? "production"
              : "warehouses/" + this.type
          }/${this.$route.params.id}?order[desc]=${
            this.order.desc ? 1 : 0
          }&order[row]=${this.order.row ? this.order.row : ""}`
        )
        .then((data) => {
          this.rows = lineOptions.init(data.rows, data.line_options);

          let status = this.rows.find((e) => e.key === "status");
          if (status !== undefined && status !== null) {
            status.values = this.incomingItemsStatuses;
            status.center = true;
          }

          this.operationType = data.data.operation_type;
          this.lineOptions = data.line_options;
          this.selectedLineOption = data.line_options[0].id;
          this.itemCount = data.data.item_operations_count;
          this.itemNum = data.data.item_operations_sum_quantity;
          this.incoming = data.data.incoming;
          this.full_price = data.data.full_price;
          if (
            data.data.full_price?.currency &&
            this.currencies.indexOf(data.data.full_price.currency) === -1
          ) {
            this.currencies.push(data.data.full_price?.currency);
            this.paymentCurrency = data.data.full_price?.currency;
          }
          this.title = data.data.title ?? " - ";
          this.delivery_price = data.data.delivery_price;
          if (data.data.total_time) {
            this.total_time = data.data.total_time;
          }
          if (data.data.total_time_left) {
            this.total_time_left = data.data.total_time_left;
          }
          this.partner = data.data.partner;
          this.warehouseIncome = data.data.warehouse_income;
          this.warehouseOutcome = data.data.warehouse_outcome;
          this.id = data.data.id;
          this.related_warehouse_operation_id =
            data.data.related_warehouse_operation_id;
          if (
            data.data?.pretty_id === null ||
            (data.data?.pretty_id?.length ?? 0) < 1
          ) {
            this.pretty_id = "#" + data.data.id;
          } else {
            this.pretty_id = data.data.pretty_id;
          }
          this.invoice = data.data.invoice_id;
          this.deliveryMethod = data.data.delivery_method_id;
          this.publicTrackingCode = data.data.public_tracking_code;
          this.createdAt = data.data.created_at;
          this.status = data.data.status !== null ? data.data.status : -3;
          this.deadlineDate = data.data.deadline_date;

          if (this.warehouseOutcome) {
            this.pages = [
              {
                title: "Order view",
                url: `/incoming/${this.$route.params.id}`,
              },
              {
                title: "Materials view",
                url: `/production/${this.$route.params.id}`,
              },
            ];
          }

          for (const product of data.data.items) {
            product.image =
              product.image !== null
                ? this.mediaServer + "/" + product.image
                : null;

            if (
              product.price2?.currency &&
              this.currencies.indexOf(product.price2?.currency) === -1
            ) {
              this.currencies.push(product.price2?.currency);
            }

            if (
              product.out_quantity !== undefined &&
              product.out_quantity !== null &&
              (product?.quantity ?? 0) > product.out_quantity
            ) {
              product.needMore = false;
            }

            if (product?.variants !== undefined) {
              for (const variant of product.variants) {
                variant.image =
                  variant.image !== null
                    ? this.mediaServer + "/" + variant.image
                    : null;
              }
            }
          }

          this.items = data.data.items;
          this.tableKey++;

          setTimeout(() => {
            if (this.warehouseOutcome) {
              new bootstrap.Tooltip(this.$refs.btn1);
              new bootstrap.Tooltip(this.$refs.btn2);
              new bootstrap.Tooltip(this.$refs.btn3);
              new bootstrap.Tooltip(this.$refs.btn4);
            }
            if (this.warehouseIncome) {
              new bootstrap.Tooltip(this.$refs.btn5);
              new bootstrap.Tooltip(this.$refs.btn6);
              new bootstrap.Tooltip(this.$refs.btn7);
              new bootstrap.Tooltip(this.$refs.btn8);
            }
          }, 100);
        });
    },
    viewTrackingCode() {
      this.loadShipBtn = true;
      if (this.deliveryMethod === null) {
        this.packagingInfo.icon = 6;
        this.packagingInfo.status = "Delivery tracking code";
        this.packagingInfo.text = this.publicTrackingCode;
        this.$refs.packagingModal.showModal();
        this.loadShipBtn = false;
      } else {
        printJS(
          `${tenant.getApiBaseUrl()}/deliveries/label/${
            this.deliveryMethod
          }?code=${this.publicTrackingCode}&_token=${store.state.token}`
        );
        this.loadShipBtn = false;
      }
    },
    updatePartner() {
      http.fetch(
        "/warehouses/outgoing/" + this.$route.params.id + "/partner",
        {
          partner: this.partner.id,
        },
        true
      );
    },
    showShippingOptions() {},
    generateShipping(automatic = true, method = null) {
      this.loadShipBtn = true;
      this.selectedDeliveryMethod = method;
      this.automaticDelivery = automatic;
      this.askDeliveryCode = true;
      setTimeout(() => {
        let modal = new bootstrap.Toast(this.$refs.askDeliveryCode, {
          autohide: false,
        });
        modal.show();
        this.loadShipBtn = false;
      }, 100);
    },
    createShipping() {
      this.loadShipBtn = true;
      http
        .fetch(
          "/warehouses/outgoing/" + this.$route.params.id + "/delivery",
          {
            tracking: this.publicTrackingCode,
            method: this.selectedDeliveryMethod,
            packages: this.packages,
            auto_delivery: this.automaticDelivery ? 1 : 0,
            courier: this.courier?.id ?? "",
          },
          true
        )
        .then((data) => {
          if (data.statuses !== undefined) {
            this.status = data.statuses.operation;
            if (this.status > 0 && this.operationType !== 5) {
              this.editable = false;
            }
          }
          this.publicTrackingCode = data.public_tracking_code;
          this.askDeliveryCode = false;
          this.deliveryMethod = this.selectedDeliveryMethod;
          this.loadShipBtn = false;
          if (this.automaticDelivery) {
            this.viewTrackingCode();
          }
        })
        .catch(() => {
          this.loadShipBtn = false;
        });
    },
    setActive() {
      this.setActiveBtn = true;
      http
        .fetch(
          "/warehouses/outgoing/" + this.$route.params.id + "/active",
          {},
          true
        )
        .then((data) => {
          if (data.statuses !== undefined) {
            this.status = data.statuses.operation;
            if (this.status > 0 && this.operationType !== 5) {
              this.editable = false;
            }
          }
          this.setActiveBtn = false;
        })
        .catch(() => {
          this.setActiveBtn = false;
        });
    },
    updateDeliveryPrice() {
      http.fetch(
        "/warehouses/outgoing/" + this.$route.params.id,
        {
          delivery_price: this.delivery_price.amount,
          delivery_price_currency: this.delivery_price.currency,
        },
        true
      );
    },
    updatePrettyId() {
      http.fetch(
        "/warehouses/outgoing/" + this.$route.params.id,
        {
          pretty_id: this.pretty_id,
        },
        true
      );
    },
    updateTitle() {
      http.fetch(
        "/warehouses/outgoing/" + this.$route.params.id,
        {
          title: this.title,
        },
        true
      );
    },
    updateDeadline() {
      http.fetch(
        "/warehouses/outgoing/" + this.$route.params.id + "/deadline",
        {
          deadline_date: this.deadlineDate,
        },
        true
      );
    },
    showStartProductionModal() {
      this.$refs.startProductionModal.showModal();
    },
    startProduction() {
      this.setActiveBtn = true;

      let data = {
        deadline_date: this.endDate,
        start_date: this.startDate,
        operation_deadline_date: this.deadlineDate,
      };

      if (this.status > -2) {
        data.restart = true;
      }

      http
        .fetch(
          "/warehouses/outgoing/" + this.$route.params.id + "/start-production",
          data,
          true
        )
        .then((data) => {
          if (data.statuses !== undefined) {
            this.status = data.statuses.operation;
            if (this.status > 0 && this.operationType !== 5) {
              this.editable = false;
            }
          }
          this.setActiveBtn = false;
        })
        .catch(() => {
          this.setActiveBtn = false;
        });
    },
    setFinished() {
      this.setActiveBtn = true;
      http
        .fetch(
          "/warehouses/outgoing/" + this.$route.params.id + "/finish",
          {},
          true
        )
        .then((data) => {
          if (data.statuses !== undefined) {
            this.status = data.statuses.operation;
            if (this.status > 0 && this.operationType !== 5) {
              this.editable = false;
            }
          }
          this.setActiveBtn = false;
        })
        .catch(() => {
          this.setActiveBtn = false;
        });
    },
    startGenerateInvoice() {
      this.askInvoicePaymentType = true;
      http.fetch("/payments/methods/").then((data) => {
        this.paymentMethods = data;
        let modal = new bootstrap.Toast(this.$refs.askInvoicePaymentType, {
          autohide: false,
        });
        modal.show();
      });
    },
    generateInvoice() {
      this.loadInvoiceBtn = true;
      this.askInvoicePaymentType = false;
      http
        .fetch(
          "/warehouses/outgoing/" + this.$route.params.id + "/invoice",
          {
            payment_method: this.paymentMethod,
            currency: this.paymentCurrency,
          },
          true
        )
        .then((data) => {
          if (data.statuses !== undefined) {
            this.status = data.statuses.operation;
            if (this.status > 0 && this.operationType !== 5) {
              this.editable = false;
            }
            if (data.invoice > 0) {
              this.invoice = data.invoice;
            }
          }
          this.loadInvoiceBtn = false;
        })
        .catch(() => {
          this.loadInvoiceBtn = false;
        });
    },
    downloadInvoice() {
      this.$refs.invoiceModal.showModal();
    },
    valUpdated(val, row, id, item) {
      if (row === "quantity" || row === "ordered_quantity") {
        http
          .fetch(
            "/warehouses/outgoing/" + this.$route.params.id + "/quantity",
            { item: id, quantity: val },
            false
          )
          .then((data) => {
            let i = this.items.indexOf(item);
            if (i > -1) {
              this.items[i].quantity = data.quantity;
            }
          })
          .catch((data) => {
            let i = this.items.indexOf(item);
            if (i > -1) {
              this.items[i].quantity = data.quantity;
            }
          });
      } else if (row === "price" || row === "original_price") {
        http
          .fetch(
            "/warehouses/outgoing/" + this.$route.params.id + "/price",
            { item: id, amount: val.amount, currency: val.currency },
            false
          )
          .then((data) => {
            let i = this.items.indexOf(item);
            if (i > -1) {
              this.items[i].price = data.converted;
              this.items[i].original_price = data.original;
            }
          });
      } else if (row === "deadline_date") {
        http.fetch(
          "/warehouses/outgoing/" + this.$route.params.id + "/deadline",
          { item: id, deadline_date: val },
          false
        );
      } else if (row === "no_need_to_send") {
        http.fetch(
          "/warehouses/outgoing/" + this.$route.params.id + "/materials",
          { item: item.variant_id, no_need_to_send: val },
          false
        );
      }
    },
    closeItem(id) {
      http
        .fetch(
          `/warehouses/${this.type}/${this.$route.params.id}/close`,
          { item: id },
          false
        )
        .then((data) => {
          this.packagingInfo.status = "Item closed";
          this.packagingInfo.text = "";
          this.packagingInfo.icon = 1;
          this.$refs.packagingModal.showModal();

          this.status = data.statuses.operation;

          let i = this.items.findIndex((e) => e.id === id);
          if (i > -1) {
            this.items[i].left_incoming = 0;
            this.packagingInfo.status = `${this.items[i].has_incoming}/${this.items[i].quantity}`;
            this.items[i].outgoing = true;
            this.items[i].incoming = true;
          }

          if (this.status > 0 && this.operationType !== 5) {
            this.editable = false;
          }
        })
        .catch((data) => {
          this.packagingInfo.icon = 2;
          this.packagingInfo.status = "Error";
          this.packagingInfo.text = data.message;
          this.$refs.packagingModal.showModal();
        });
    },
    packaging(id, quantity = 1) {
      http
        .fetch(
          `/warehouses/${this.type}/${this.$route.params.id}/packaging`,
          { item: id, quantity: quantity },
          false
        )
        .then((data) => {
          this.packagingInfo.status =
            this.type === "outgoing"
              ? "Item added to the package"
              : "Receiving item";
          this.packagingInfo.text =
            this.type === "outgoing"
              ? "Item added to the package"
              : "Receiving items to the warehouse";
          this.packagingInfo.icon = 1;
          this.$refs.packagingModal.showModal();

          this.status = data.statuses.operation;

          let i = this.items.findIndex((e) => e.id === id);
          if (i > -1) {
            if (this.type === "outgoing") {
              this.items[i].left_outgoing -= quantity;
              this.items[i].has_outgoing -= quantity * -1;
              this.packagingInfo.status = `${this.items[i].has_outgoing}/${this.items[i].quantity}`;
            } else {
              this.items[i].left_incoming -= quantity;
              this.items[i].has_incoming -= quantity * -1;
              this.packagingInfo.status = `${this.items[i].has_incoming}/${this.items[i].quantity}`;
            }
            this.packagingInfo.text =
              this.type === "outgoing"
                ? `<div>${this.items[i].name} added to the package</div>`
                : `<div>${this.items[i].name} received to the warehouse</div>`;
            if (data.statuses.item === 1) {
              this.items[i].outgoing = true;
            }
            if (data.statuses.item === 2) {
              this.items[i].incoming = true;
            }
          }

          if (this.status > 0 && this.operationType !== 5) {
            this.editable = false;
          }
          if (this.status === 2) {
            this.packagingInfo.icon = 4;
            this.packagingInfo.status =
              "The package is now fully packed and ready for delivery!";
          } else if (this.status === 6) {
            this.packagingInfo.icon = 5;
            this.packagingInfo.status = "The package is now fully unpacked!";
          }
        })
        .catch((data) => {
          this.packagingInfo.icon = 2;
          this.packagingInfo.status = "Error";
          this.packagingInfo.text = data.message;
          this.$refs.packagingModal.showModal();
        });
    },
    deleteItem() {
      let data = [];
      for (const item of this.selectedItems) {
        data.push(item.id);
      }
      http
        .fetch(
          "/warehouses/income/" + this.$route.params.id,
          { rows: data },
          false,
          "DELETE"
        )
        .then(() => {
          for (const item of this.selectedItems) {
            let i = this.items.indexOf(item);
            if (i > -1) {
              this.items[i].quantity = 0;
            }
          }
        });
    },
    searchCourier(search, loading) {
      if (search.length < 2) {
        return;
      }
      loading(true);
      let lastSearchId = Math.random();
      this.lastSearchId = lastSearchId;
      http
        .fetch(`/deliveries/search-courier`, { q: search })
        .then((data) => {
          if (this.lastSearchId === lastSearchId) {
            this.couriers = data.data;
            loading(false);
          }
        })
        .catch(() => {
          loading(false);
        });
    },
  },
};
</script>
